import {request_async} from "@/utils/requestAsync";
import {API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

// 获取列表
export async function getList(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionExam/list`, "get", params);
}

// 新增
export async function save(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionExam/save`, "post_json", params);
}

// 修改
export async function update(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionExam/update`, "post_json", params);
}

// 删除
export async function remove(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionExam/delete`, "post_json", params);
}

// 信息
export async function getOne(id) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionExam/info/` + id, "get", {});
}

// 获取某场考核的所有学生列表和答卷列表
export async function getOneExamAllResultList(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionExam/oneExamUserAllList`, "post_body", params);
}

// 获取某个考核的当前统计状态
export async function getOneExamStatisticInfo(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionExam/oneExamStatisticInfo`, "post_body", params);
}

// 获取某个考核的结果分析
export async function getOneExamResultAnalysis(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionExam/oneExamResultAnalysis`, "post_body", params);
}

// 主动结束考核
export async function endExam(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionExam/endExam`, "post_body", params);
}