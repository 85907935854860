import {
  getList,
  save,
  update,
  remove,
  getOne,
  getOneExamAllResultList,
  getOneExamStatisticInfo,
  getOneExamResultAnalysis,
  endExam
} from "@/api/exp/QuestionExamApi";
import {CommonModel} from "@/model/CommonModel";

/**
 课堂考核-考核model
 */
class QuestionExamModel {
  // 获取列表
  static async getList(page, size, query) {
    let params = {
      page: page,
      size: size,
    }
    params = Object.assign(params, query)
    let [data] = await getList(params)
    if (size === 0) {  // 不分页
      return data.data;
    } else { // 分页
      let list = CommonModel.generateListMybatisPlus(data.data)
      // 遍历config转换为对象
      let listData = list[0]
      return [listData, list[1]];
    }
  }

  // 新增
  static async save(entity) {
    let [res] = await save(entity)
    if (res.code === 20000) {
      return res.data
    } else {
      return false
    }
  }

  // 修改
  static async update(entity) {
    let [res] = await update(entity)
    if (res.code === 20000) {
      return true
    } else {
      return false
    }
  }

  // 删除
  static async remove(idArray) {
    let [res] = await remove(idArray)
    if (res.code === 20000) {
      return true
    } else {
      return false
    }
  }

  // 获取一个
  static async getOne(id) {
    let [res] = await getOne(id)
    if (res.code === 20000) {
      return res.data
    } else {
      return false
    }
  }

  // 获取某场考核的所有学生列表和答卷列表
  static async getOneExamAllResultList(params) {
    let [res] = await getOneExamAllResultList(params)
    if (res.code === 20000) {
      return res.data
    } else {
      return false
    }
  }

  // 获取某个考核的当前统计状态
  static async getOneExamStatisticInfo(params) {
    let [res] = await getOneExamStatisticInfo(params)
    if (res.code === 20000) {
      return res.data
    } else {
      return false
    }
  }

  // 获取某个考核的结果分析
  static async getOneExamResultAnalysis(params) {
    let [res] = await getOneExamResultAnalysis(params)
    if (res.code === 20000) {
      return res.data
    } else {
      return false
    }
  }

  // 主动结束考核
  static async endExam(params) {
    let [res] = await endExam(params)
    if (res.code === 20000) {
      return res.data
    } else {
      return false
    }
  }
}

export {QuestionExamModel}
